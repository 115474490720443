<template>
    <div>
        <div class='d-flex justify-content-between neue mb-3'>
            <h4>{{ $t('components.service.modal.WidgetData.1') }} {{ this.content.name }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div>
            <h5 class="fs_10 break-word">{{ $t('components.service.modal.PayHref.17') }}: <span class='href' v-on:click="this.redir">{{ this.doc }}</span></h5>
        </div>
        <div class="mb-3">
            <Select :label="$t('components.service.modal.WidgetData.3')" :require="true"  id='keyt' :keyt='true'  :first='true' :keywords.sync='this.keywords' @updateParentState="this.updateState" />
        </div>
        <pre v-if="this.stringData"  class=" d-flex justify-content-center fs_09 color-acent">
            <code>
                {{ this.stringData }}
            </code>
        </pre>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="$t('components.service.modal.WidgetData.2')" class='mr-1' type='btn-outline-warning' 
            v-on:click='() => {
                this.close();
            }'/>
            <Button :name="$t('components.service.modal.WidgetData.4')" type='btn-warning' :disabled="!this.keyt" v-on:click="this.createCode"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';

export default {
    components: {
        Button,
        Select
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            doc: this.$store.state.modal.url.WID_DOC,
            url: this.$store.state.modal.url.WIDGET_URL,
            keywords: [],
            keyt: '',
            stringData: '',
        }
    },
    mounted() {
        console.log(this.$store.state);
    },
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    methods: {
        getKeyts(k) {
            this.keywords = k;
        },
        updateState(arg) {
            this.keyt = arg.value;
            if (this.stringData) {
                this.createCode()
            }
        },
        redir() {
            window.open(this.doc, '_blank').focus();
        },
        createCode() {
            this.stringData = 
`
let dataObject = {
    frame_id: "${this.content.num}_id",
    frame_name: "${this.content.num}_name",
    proc_url: "${this.url}",
    pay_params: {
        comment: "${this.$t('components.service.modal.WidgetData.5')} ${this.content.name}",
        keyt_shop: "${this.keyt}",
        num_shop: "${this.content.num}",
        summ: "0", /// ${this.$t('components.service.modal.WidgetData.6')}
    },
    "onSuccess": function (result) {
        console.log(result) /// ${this.$t('components.service.modal.WidgetData.7')}
    },
    "onFail": function (result) {
        console.log(result) /// ${this.$t('components.service.modal.WidgetData.8')}
    }
}`;
        }
    },
}
</script>

<style lang="scss" scoped>
    
</style>