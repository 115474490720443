<template>
    <textarea v-if="this.FactType == 'textarea'" :id="id" ref='input' class="form-control input-container"  :style="this.style" :class='{"invalid": this.invalid, "success": this.success}' :placeholder="this.place" :autocomplete="this.autocomplete" :disabled='this.disabled' v-on:blur="this.blur" v-model="this.value" v-on:input="this.setValue"></textarea>
    <div class='wid position-relative d-flex flex-row align-items-center justify-content-start' v-else>
        <input :id="id" :type='this.FactType' ref='input' 
        :style="this.style" 
        :autocomplete="this.autocomplete" 
        :placeholder="this.place" 
        :class='{"invalid": this.invalid, "success": this.success}' 
        class="form-control input-container" 
        :disabled='this.disabled' 
        v-model="this.value" 
        v-on:blur="this.blur" 
        v-on:input="this.setValue"
        v-on:keyup.enter="this.onEnter">
        <div v-if="this.origType == 'password'" 
            class="eye-wrap" 
            :class="{'not_single': this.invalid || this.success, 'single': !this.invalid && !this.success}"
            v-on:click="this.passWisibility">
            <i class="item-i" :class="{'eye-off': this.FactType == 'password', 'eye': this.FactType != 'password'}"></i>
        </div>
        <div v-if='this.invalidHint && this.invalid' :id="this.id + '_warning'" class="invalid-tooltip">
            {{this.invalidHint}}
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            place: this.placeholder ? this.placeholder : '',
            sizeW: this.$store.state.resize.w,
            FactType: this.type, 
            origType: this.type
        }
    },
    props: {
        id: [String, Boolean],
        type: [String],
        dataValue: [String, Boolean, Number], 
        disabled: [String, Boolean],
        autofocus: [Boolean],
        regCheck: [Object, Boolean],
        invalid: [Boolean, String],
        success: [Boolean], 
        invalidHint: [String, Boolean],
        placeholder: [String, Boolean], 
        style: [Boolean, String],
        autocomplete: {
            type: [String, Boolean],
            default: false,
        },
    },
    mounted() {
        if (this.autofocus) {
            this.$nextTick(() => this.focusInput())
        }
    },
    methods: {
        setValue() {
            if (this.regCheck) {
                let pre_value = this.regCheck.f(this.value);
                if (pre_value === false) {
                    return;
                }
                this.value = this.regCheck.f(this.value);
            }
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value
            })
        },
        blur() {
            this.$emit('blurInput', {
                id: this.id,
                value: this.value
            })
        },
        onEnter() {
            this.$emit('onEnter');
        },
        focusInput() {
            this.$refs.input.focus();
        },
        passWisibility() {
            if (this.FactType == 'password') {
                this.FactType = 'text'
            } else {
                this.FactType = 'password'
            }
        }
    },
    watch: {
        dataValue(newV, oldV) {
            // if (newV != oldV) {
                // console.log(newV, oldV)
                this.value = newV ? newV : '';
            // }
        }
    }

}
</script>

<style lang="scss" scoped>
.invalid-feedback {
    display: block;
}
.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
}
.eye-wrap {
    position: absolute; 
    cursor: pointer;
}
.single {
    right: 0,
}
.not_single {
    right: 28px
}
.success {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('~@/assets/icons/check.svg');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: $color-system-green;
    box-shadow: none;
}
.invalid-tooltip {
    display: block;
}
input::placeholder {
    color: $color-grey !important;
}
input {
    font-family: "HelveticaNeue roman";
}
.wid {
    width: 100%;
}
</style>