<template>
    <div ref='s_cont' :id="id + '_s'" :class="{'disable': this.disable, 'invalid': this.invalid, 'mh-ks': this.keyt}" class='input-container form-control' tabindex='-1' v-on:click="this.showOpt()">
        <div v-if="this.keyt && this.keywords[this.name]" class="input-container-select table-responsive" :id="id">
          <table class="table m-0 neue-roman mr-1">
            <tbody class='k_table'>
                <tr>
                    <td class="align-middle" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; padding-right: 0px !important; width: 32px;'" scope="col">
                      <div v-if="this.keywords[this.name].image">
                        <i class="item-i contain c28" :style="`background-image: url('${this.keywords[this.name].image}');`"></i>
                      </div>
                      <div v-else class="simp-bg k-symbol">
                        <span class="">
                          {{this.keywords[this.name].symbol}}
                        </span>
                      </div> 
                    </td>
                    <td class="align-middle alter-col" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; max-width: 2em;'" scope="col">
                      <div class='href neue-roman d-flex flex-row'>
                        <span :style="'white-space: nowrap'">{{this.sized(this.keywords[this.name].code)}}</span>
                      </div>
                      <span class='grey light fs_09' :style="'white-space: nowrap'" :title='this.keywords[this.name].name'>{{this.sizedName(this.keywords[this.name].value || this.keywords[this.name].name)}}</span></td>
                    <td class="align-middle alter-col" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'" scope="col"><span class='neue-roman fs_13'>{{ this.keywords[this.name].balance }}</span> <span class='grey fs_08'>{{this.keywords[this.name].curr}}</span></td>
                </tr>
            </tbody>
          </table>
          <div class="c-arrow"><i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i></div>
        </div>
        <div v-else class="input-container-select" :class="{'mh-ks': this.keyt}" :id="id">
            <!-- <span class="input-container-select-value" 
            v-html="this.keywords[this.name].value ? this.keywords[this.name].value : this.keywords[this.name].name"></span> -->
            <span class="input-container-select-value" v-html="this.name.n" :class='{"grey": this.name.g}'></span>
            <div class="c-arrow"><i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i></div>
        </div> 

        
        <transition v-if="this.keyt" @enter="enter" @leave="leave">
            <div ref='o_cont' :style='"max-height: " + this.listHeight + "px; top: 3.69rem; padding-top: 0 !important; padding-bottom: 0 !important;"' v-if="showOptions && !disable" class='content p-3 table-responsive input-container-select-options'>
            <table v-if='this.keywords.length > 0' class="table m-0 neue-roman">
            <tbody class='k_table'>
                <tr class="k_tr mh-ks" v-for="row in this.keywords" :id="'c-ss-k-'+row.keyt" :key="row.keyt" v-on:click="() => {this.selectOption(row.code)}">
                    <td class="align-middle alter-col" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; padding-right: 0px !important; width: 32px'" scope="col">
                      <div v-if="row.image">
                        <i class="item-i contain c28" :style="`background-image: url('${row.image}');`"></i>
                      </div>
                      <div v-else class="simp-bg k-symbol">
                        <span>
                          {{row.symbol}}
                        </span>
                      </div> 
                    </td>
                    <td class="align-middle alter-col" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'" scope="col">
                      <div class='href neue-roman d-flex flex-row'>
                        <span :style="'white-space: nowrap'">{{this.sized(row.code)}}</span>
                      </div>
                      <span class='grey light fs_09' :style="'white-space: nowrap'">{{this.sizedName(row.value || row.name)}}</span></td>
                    <td class="align-middle alter-col" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'" scope="col">
                        <span class='neue-roman fs_13'>{{ row.balance }}</span> <span class='grey fs_08'>{{row.curr}}</span>
                    </td>
                </tr>
            </tbody>
            </table>
            </div>
        </transition>
        <transition v-else @enter="enter" @leave="leave">
            <ul ref='o_cont' class="input-container-select-options" :style='"max-height: " + this.listHeight + "px; top: 2.17rem;"' v-if="showOptions && !disable">
                <li class="input-container-select-options-li" v-for="keyword in this.keywords" :id="'c-ss-i-'+keyword.code" :key="keyword.code" :class="{'acted': this.value == keyword.code}" v-on:click="() => {this.selectOption(keyword.code)}">
                    <label v-html="keyword.value || keyword.name"></label>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { gsap } from "gsap";

export default {
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            showOptions: false,
            name: this.dataValue ? this.getOption(this.dataValue) : '',
            listHeight: '200',
            elemWidth: false,
        }
    },
    props: {
        id: [String, Boolean],
        keywords: [Array, String, Boolean],
        dataValue: [String, Boolean],
        invalid: [Boolean],
        keyt: {
            default: false,
            type: [Boolean],
        },
        hotUpdate: {
            default: false,
            type: [Boolean],
        },
        disable: {
            default: false,
            type: [Boolean]
        }
    },
    mounted() {
        console.log(this.keywords);
        console.log('DataValue', this.dataValue)
        // new ResizeObserver(outputsize).observe(this.$refs.s_cont),
        this.elemWidth = this.$refs.s_cont.offsetWidth;
    },
    beforeUnmount() {
        document.querySelector('body').removeEventListener('click', this.clickClose);
    },
    watch: {
        dataValue(newV, oldV) {
            if (this.hotUpdate) {
                this.selectOption(newV, true);
            }
        }
    },
    methods: {
        // obs_call() {
        //     this.elemWidth = this.$refs.s_cont.offsetWidth;
        // }, 
        sized(str) {
            // console.log(this.elemWidth)
            if (this.elemWidth && this.elemWidth < 500 && str.length > 7) {
                return '* ' + str.substring(str.length - 5)
            }
            return str;
        },
        sizedName(str) {
            if (!str) {
                return '';
            }
            if (this.elemWidth < 300 && str.length > 10) {
                return str.substring(0, 10) + '...';
            }
            if (this.elemWidth < 400 && str.length > 15) {
                return str.substring(0, 15) + '...';
            }
            if (this.elemWidth < 500 && str.length > 20) {
                return str.substring(0, 20) + '...';
            }
            if (this.elemWidth < 600 && str.length > 30) {
                return str.substring(0, 30) + '...';
            }
            if (str.length > 50) {
                return str.substring(0, 50) + '...';
            }
            return str;
        },
        setValue(repeat = false) {
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value,
                repeat: repeat,
            });
            this.blur();
        },
        blur() {
            this.$emit('blurInput', {
                id: this.id,
                value: this.value
            })
        },
        sortArr() {
            let arr = this.keywords;
            arr.sort((r1, r2) => {
                if (r1.code != this.value && r2.code != this.value ) {
                    return 0;
                }
                if (r1.code == this.value && r2.code != this.value) {

                    return -1;
                }
                if (r1.code != this.value && r2.code == this.value) {
                    return 1;
                }
            });
            console.log(arr);
        },
        showOpt() {
            if (this.disable) {
                this.showOptions = false;
                document.querySelector('body').removeEventListener('click', this.clickClose);
                return;
            }
            // this.sortArr();
            
            this.listHeight = this.getHeight();
            this.showOptions = !this.showOptions;
            this.showOptions ? this.$refs.s_cont.focus() : this.$refs.s_cont.blur();
            document.querySelector('body').addEventListener('click', this.clickClose);
        },
        getHeight() {
            let list = this.$refs.s_cont.getBoundingClientRect();
            let box = document.querySelector('#app').getBoundingClientRect();
            return String((box.bottom + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
        clickClose(e) {
            if (!this.$el.contains(e.target)) {
                this.showOptions = false;
                document.querySelector('body').removeEventListener('click', this.clickClose);
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.6, height: '50%', duration: 0.2}, {opacity: 1, height: 'auto', duration: 0.2});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.6, height: 0, duration: 0.1, onComplete: done});
        },
        getOption(code) {
            if (this.keyt) {
                let val = '';
                this.keywords.forEach((e, i) => {
                    if (e.code == code) {
                        val = i;
                    }
                });
                return val;
            } else {
                // console.log(code)
                let val = {};
                this.keywords.forEach(e => {
                    if (e.code == code) {
                        if (e.place) {
                            val.g = true;
                        } else {
                            val.g = false;
                        }
                        val.n = e.value || e.name;
                    }
                });
                return val;
            }
        },
        selectOption(value, repeat = false) {
            this.name = this.getOption(value);
            this.value = value;
            if (!repeat) {
                this.setValue(repeat);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 1.6rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
}
.mh-ks {
    min-height: 60.97px;
    height: 60.97px;
}
.input-container:focus:not(.disable) {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.disable {
    background-color: $color-lightgrey;
    cursor: default !important;
}
.acted {
    background-color: $color-light;
    font-family: HelveticaNeue bold;
}
.alter-col {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
}
.k-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 1rem;
        display: flex;
        align-items: center;
    }
}
.k_tr {
    padding: 0.375rem 0.75rem !important; 
}
.input-container {
        padding: 0;
        position: relative;
        cursor: pointer;
        &-select {
            width: 100%;
            z-index: 2000;
            outline:none;
            border: none;
            padding: 0.375rem 0.75rem;
            background: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: 'HelveticaNeue roman';
            .c-arrow, .c-arrow {
                height: 24px;
            }
            &-value {
                height: min-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-options {
                overflow: hidden;
                padding: 0;
                border-bottom: 1px solid #ced4da;
                border-left: 1px solid #ced4da;
                border-right: 1px solid #ced4da;
                left: -0.06rem;
                right: -0.06rem;
                background: #fff;
                position: absolute;
                overflow: auto;
                max-height: 280px;
                z-index: 1000;
                margin: 0;
                list-style-type: none;
                font-family: 'HelveticaNeue roman';
                border-radius: 0 0 0.25rem 0.25rem;
                &-li {
                    padding: 0.375rem 0.75rem;
                    label {
                        font-weight: regular;
                        cursor: pointer;
                    }
                    border-top: 1px solid $color-light;
                }
                &-li:hover {
                    background-color: $color-lightgrey;
                }
            }
        }
}
</style>