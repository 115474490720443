<template>
    <div class='container-fluid p-0 d-flex auth-bg justify-content-center align-items-center min-vh-100 w-100 position-relative'>
        <transition @enter="enter" @leave="leave">
        <div v-if="this.stage == 'registration'" class='container-fluid p-0 d-flex h-100 m-0'>
                <Registration class='col-12 col-md-7'  @updateParentState="this.backStage()"/>
            <div class='col-md-5 d-none d-md-flex p-0 justify-content-center align-items-cnter'>
                <img v-if="this.$store.state.logo.img != 'logoDefault.svg'" :style='this.$store.state.logo.style' style='max-width: 200px' :src="this.$store.state.logo.img">
                <img v-else :style='this.$store.state.logo.style' style='max-width: 200px' :src="require(`@/assets/${this.$store.state.logo.img}`)">
            </div>
        </div>
        </transition>
        <transition @enter="enterA" @leave="leaveA">
        <div v-if="this.stage != 'registration'" :class="`col-xl-5 col-sm-7 auth-width auth-container`">
            <div class='mx-auto bp-1 auth-logo'>
                <img v-if="this.$store.state.logo.img != 'logoDefault.svg'" :style='this.$store.state.logo.style' :src="this.$store.state.logo.img">
                <img v-else :style='this.$store.state.logo.style' :src="require(`@/assets/${this.$store.state.logo.img}`)">
            </div>
            <div v-if="this.stage == 'auth'" class='content d-flex flex-column justify-content-around align-items-center'>
                    <h3 class='mt-4 neue-bold'>{{ $t('views.Login.1') }}</h3>
                    <div class='container-fluid p-0'>
                        <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.2') }}</p>
                        <InputSimple id="cabinet_login" autofocus @keyup="this.next" @updateParentState="this.updateState"/>
                    </div>
                    <div class='container-fluid p-0'>
                        <p class='mt-4 mb-1 neue-roman'>{{ $t('views.Login.3') }}</p>
                        <InputSimple ref="pass" type='password' id="pass" @keyup="this.submit" @updateParentState="this.updateState"/>
                        <p class='mt-1 grey pointer' id="v-l-1" v-on:click='() => {this.stage = "recover"}'>{{ $t('views.Login.4') }}</p>
                    </div>
                    <div class='mx-auto mt-4'>
                        <Button :name="$t('views.Login.15')" id="v-l-2"  :style='"width: 120px"' type='btn-warning' v-on:click="this.login"/>
                    </div>
                    <div class='text-center mt-4 neue-roman'>
                        <p class='alt-mb'>{{ $t('views.Login.5') }}<span v-on:click="this.stage = 'registration'" id="v-l-3" class='href ml-1'>{{ $t('views.Login.6') }}</span></p>
                    </div>
            </div>
            <div v-if="this.stage == 'recover'" class='content d-flex flex-column justify-content-around align-items-center'>
                <h3 class='mt-4 neue-bold'>{{ $t('views.Login.7') }}</h3>
                <div class='container-fluid p-0'>
                    <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.8') }} </p>
                    <InputSimple id="info" @updateParentState="this.updateState"/>
                </div>
                <div class='mx-auto mt-4  d-flex col-12 justify-content-center'>
                    <Button :name="$t('views.Login.16')" :class="'mr-1'" id="v-l-4" type='btn-outline-warning' v-on:click='() => {this.stage = "auth"}'/>
                    <Button :name="$t('views.Login.18')" id="v-l-5" :disabled='this.data.info.length < 1 ? true : false' type='btn-warning' v-on:click="this.find_recover"/>
                </div>
            </div>
            <div v-if="this.stage == 'ask'" class='content d-flex flex-column justify-content-around align-items-center'>
                <h3 class='mt-4 neue-bold'>{{ $t('views.Login.10') }}</h3>
                <div class='container-fluid p-0'>
                    <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.11') }} </p>
                    <InputSimple id="surname" @updateParentState="this.updateState"/>
                </div>
                <div class='container-fluid p-0'>
                    <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.12') }} </p>
                    <InputSimple id="firstname" @updateParentState="this.updateState"/>
                </div>
                <div class='container-fluid p-0'>
                    <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.13') }} </p>
                    <InputSimple id="patronymic" @updateParentState="this.updateState"/>
                </div>
                <div class='container-fluid p-0'>
                    <p class='mt-2 mb-1 neue-roman'>{{ $t('views.Login.14') }} </p>
                    <InputSimple id="birth_date" @updateParentState="this.updateState"/>
                </div>
                <div class='mx-auto mt-4  d-flex col-12 justify-content-center'>
                    <Button :name="$t('views.Login.16')" id="v-l-6" :class="'mr-1'" type='btn-outline-warning' v-on:click='() => {this.stage = "recover"}'/>
                    <Button :name="$t('views.Login.18')" id="v-l-7" type='btn-warning' v-on:click="this.hard_recover"/>
                </div>
            </div>
            <div v-if="this.stage == 'finded'" class='content d-flex flex-column justify-content-around align-items-center'>
                <h3 class='mt-4 fs_11 neue-bold'>{{ $t('views.Login.19') }}</h3>
                <div class='container-fluid p-0'>
                    <p class='mt-2 fs_11 mb-1 text-center neue-roman'>{{ this.user.name }}</p>
                </div>
                <div class='mx-auto mt-4  d-flex col-12 justify-content-center'>
                    <Button :name="$t('views.Login.16')" id="v-l-8" :class="'mr-1'" type='btn-outline-warning' v-on:click='() => {this.stage = "recover"}'/>
                    <Button :name="$t('views.Login.9')" id="v-l-9" :disabled='this.user ? false: true' type='btn-warning' v-on:click="this.recover"/>
                </div>
            </div>
        </div>
        </transition>
    </div>
    <div class="auth-ls d-flex flex-column justify-content-around align-items-center p-3">
        <LangSwitcher :type="'auth'"/>
        <i class='mt-2 item-i h-phones pointer'></i>
    </div>
</template>

<script>
import InputSimple from '@/components/service/InputSimple';
import Button from '@/components/service/Button';
import Registration from '@/components/service/Registration';
import { gsap } from "gsap";
import LangSwitcher from '@/components/service/LangSwitcher';

export default {
    components: {
        InputSimple,
        Button,
        Registration,
        LangSwitcher
    },
    data() {
        return {
            data: {
                cabinet_login: '',
                pass: '',
                info: '',
                surname: '',
                firstname: '',
                patronymic: '',
                birth_date: '',
            },
            stage: 'auth',
            timeOut: false,
            continue: false,
            ask: false,
            user: false,
        }
    },
    mounted() {
        if (this.$store.state.isLogged) {
            this.$store.dispatch('logout', this.$router);
        }
        let b = document.querySelector('body');
        let w  =document.querySelector('.wrapper');
        b.classList.add('before-bg');
        w.classList.add('beforew-bg');
        b.classList.remove('after-bg');
        w.classList.remove('afterw-bg');
    },
    created() {
        console.log(this.$route)
        console.log('state', this.$store.state)
        if (this.$route.path == '/reg') {
            this.stage = 'registration';
        }
    },
    beforeUnmount() {
        let b = document.querySelector('body');
        let w  =document.querySelector('.wrapper');
        b.classList.remove('before-bg');
        b.classList.add('after-bg');
        w.classList.remove('beforew-bg');
        w.classList.add('afterw-bg');
    },
    methods: {
        login() {
            this.axios.post('login', {data: {cabinet_login: this.data.cabinet_login, password: this.data.pass}}).then(
                (res) => {
                    this.$store.commit('USER_DATA', res.data.body);
                    // this.$store.dispatch('informer');
                }
            );
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        next(e) {
            if (e.keyCode === 13) {
                this.$refs.pass.focusInput();
            }
        },
        submit(e) {
            if (e.keyCode === 13) {
                this.login();
            }
        },
        find_recover() {
            this.axios.post('checkUser', {
                data: {info: this.data.info}
            }).then(res => {
                if (res.data.body.tables[0].colvalues.length > 0) {
                    this.user = res.data.body.tables[0].colvalues[0];
                    this.stage = 'finded';
                } else {
                    this.stage = 'ask';
                    this.user = false;
                }
            })
        },
        hard_recover() {
            this.axios.post('findUser', {
                data: {
                    last_name: this.data.surname,
                    first_name: this.data.firstname,
                    midle_name: this.data.patronymic,
                    birth_date: this.data.birth_date,
                }
            }).then(res => {
                if (res.data.body.tables[0].colvalues.length > 0) {
                    this.user = res.data.body.tables[0].colvalues[0];
                    this.stage = 'finded';
                } else {
                    this.stage = 'ask';
                    this.user = false;
                }
            })
        },
        recover() {
            this.axios.post('recoverPass', {
                    data: {info: this.data.info, user: this.user}
                }).then(res => {
                    if (res.data.body.result == '0') {
                        this.stage = 'auth';
                    }
                })
        },
        backStage() {
            this.data = {
                cabinet_login: '',
                pass: '',
                info: '',
                surname: '',
                firstname: '',
                patronymic: '',
                birth_date: '',
            };
            this.stage = 'auth';
            this.timeOut = false;
            this.continue = false;
            this.response = false;
            this.user = false;
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.5, x: '-80%'}, {opacity: 1, x: '0', duration: 0.7});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1, x: '0'}, {opacity: 0.1, x: '-80%', duration: 0.7, position: 'absolute', onComplete: done});
        },
        enterA(el) {
            gsap.fromTo(el, {opacity: 0.5, x: this.$store.state.resize.w > 767 ? '+5%': '+10%'}, {opacity: 1, x: '0', duration: 0.7});
        },
        leaveA(el, done) {
            gsap.fromTo(el, {opacity: 1, x: '0'}, {opacity: 0.1, x: '+200%', duration: 0.7, position: 'absolute', onComplete: done});
        },
    }
}
</script>

<style lang="scss">
.auth-logo {
    max-width: min-content;
}
.auth-bg {
    // background-image: url($bg-image);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-clip: padding-box;
    overflow-x: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.auth-container {
    .content {
        padding: 1rem 1.3rem;
    }
    .alt-mb {
        margin-bottom: 0.6rem;
    }
}
.auth-width {
    max-width: 465px;
}
.auth-ls {
    position: fixed;
    right: 0;
    top: 30%;
    background-color: $color-acent;
    border-radius: 12px 0 0 12px;
}
</style>